import React, { useState, useEffect } from 'react';
import { MessageDto } from "../models/MessageDto";
import ReactMarkdown from 'react-markdown';
import ReactDom from 'react-dom';
import { Box } from "@mui/material";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard} from 'react-copy-to-clipboard';


interface MessageProps {
  message: MessageDto;
  onNavigate?: (url: string) => void; // Made optional to maintain compatibility
}

const Message: React.FC<MessageProps> = ({ message, onNavigate }) => {
  // Define link click handler function
  const handleLinkClick = (href: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onNavigate) {
      onNavigate(href);
    } else {
      window.open(href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Box
      sx={{
        textAlign: message.isUser ? "right" : "left",
        margin: "8px"
      }}
    >
      
      <Box
        sx={{
          display: 'inline-block',
          color: message.isUser ? "#ffffff" : "#000000",
          backgroundColor: message.isUser ? "#1186fe" : "#eaeaea",
          padding: "15px",
          borderRadius: "8px",
          maxWidth: "70%",
          wordBreak: "break-word"
        }}
      >
        
        <ReactMarkdown
          components={{
            //Hyperlinks
            a: ({ href = '', children }: { href?: string; children: React.ReactNode }) => (
              <a
                href={href}
                onClick={href ? handleLinkClick(href) : undefined}
                style={{
                  color: message.isUser ? "#ffffff" : "#0066cc",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
              >
                {children}
              </a>
            ),
            //Code Blocks
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              const codeText = String(children).replace(/\n$/, '');
              const [copied, setCopied] = useState(false);

              useEffect(() => {
                let timer;
                if (copied) {
                  timer = setTimeout(() => setCopied(false), 2000);
                }
                return () => {
                  if (timer) clearTimeout(timer);
                };
              }, [copied]);

              const handleCopyClick = () => {
                console.log("Button Clicked")
              };

              return !inline && match ? (
                <div style={{ position: 'relative' }}> {}
                  <SyntaxHighlighter
                    style={{
                      ...oneDark, 
                      'code[class*="language-"]': {  
                          ...(oneDark['code[class*="language-"]'] || {}),
                          fontSize: '12px',
                      },
                    }}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  >
                    {codeText}
                  </SyntaxHighlighter>
                  
                  <CopyToClipboard text={codeText} onCopy={() => setCopied(true)}>
                    <button
                      onClick={handleCopyClick}
                      style={{
                        position: 'absolute',
                        top: '12px',
                        right: '8px',
                        padding: '4px 8px',
                        backgroundColor: '#666',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '0.75em',
                      }}
                    >
                      {copied ? 'Copied' : 'Copy'}
                    </button>
                  </CopyToClipboard>
                </div>
              ) : (
                <code className={className} {...props} style={{ whiteSpace: 'pre-wrap' }}>
                  {children}
                </code>
              );
            }
          }}
        >
          {message.content}
        </ReactMarkdown>
      </Box>
    </Box>
  );
};

export default Message;